@function get-background-color() {
  @return var(--backgroundColor);
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Noto Sans TC', sans-serif;
  background-color: get-background-color();
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
