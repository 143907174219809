@import '../general.scss';
@import '../function.scss';
@import '../EditorPage/WorkingArea.scss';
@import '../HomePage/index.scss';
@import '../SavePage/index.scss';

$resultPage-title-height: get-vh(90px);
$resultPage-button-height: get-vh(60px);
$resultPage-bottomRow-button-height: get-vh(40px);

@function get-resultPage-scale() {
  @return calc(var(--mobileScale) * var(--resultPageScale));
}

@function get-resultPage-image-scale() {
  @return calc(
    var(--mobileScale) * var(--resultPageScale) * #{$workingArea-width-general-no-pixel} /
      var(--outputImageWidth)
  );
}

@function getResultImageMargin() {
  @return calc(var(--resultImageMargin) * 1px);
}

@mixin resultPage-button {
  height: $resultPage-bottomRow-button-height;
  line-height: $resultPage-bottomRow-button-height;
  border: 0;
  padding: 0;

  width: 100%;
  border-radius: get-vw(50px);
  box-shadow: 0 0 get-vw(20px) rgba(0, 0, 0, 0.25);
  @content;
}

#resultAreaWrapper {
  display: flex;
  justify-content: center;
  position: absolute;
  left: -1000px;
}

#resultImageWrapper {
  position: relative;
}

#resultImageWrapper:lang(de),
#resultImageWrapper:lang(en),
#resultImageWrapper:lang(es),
#resultImageWrapper:lang(fr),
#resultImageWrapper:lang(ja),
#resultImageWrapper:lang(ru) {
  transform-origin: top center;
  transform: scale(0.97);
}

.resultPage {
  text-align: center;
  height: getInnerHeight();
  color: white;

  &-area {
    @include workingArea {
      -webkit-transform: scale(get-resultPage-scale());
      transform: scale(get-resultPage-scale());
      transition: all 0s;
    }
  }

  &-image {
    background: rgba(0, 0, 0, 0.1);
    box-shadow: 0 get-vw(4px) get-vw(10px) rgba(0, 0, 0, 0.25);
    transform-origin: 0 0;
    transform: translatex(getResultImageMargin())
      scale(get-resultPage-image-scale());
    // outline: get-vw(20px) solid #ffffff;
  }

  &-image404 {
    margin-top: get-vh(15px);
    width: 60%;
  }

  &-404text {
    margin-top: get-vh(27px);
    font-size: get-vw(12px);
  }

  &-logoAndForest {
    @include logoAndForest;
  }

  &-logoAndForest:lang(de),
  &-logoAndForest:lang(en),
  &-logoAndForest:lang(es),
  &-logoAndForest:lang(fr),
  &-logoAndForest:lang(ja),
  &-logoAndForest:lang(ru) {
    padding-top: get-vh(10px);
    margin-bottom: get-vh(10px);
  }

  &-title {
    @include title;
    margin-bottom: get-vh(21px);
    user-select: none;
  }

  &-title:lang(de),
  &-title:lang(en),
  &-title:lang(es),
  &-title:lang(fr),
  &-title:lang(ja),
  &-title:lang(ru) {
    height: get-vh(120px);
  }

  &-text {
    width: get-vw(160px);
    height: auto;
    padding: get-vw(5px);
    line-height: auto;
    position: absolute;
    z-index: 1;

    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;

    background: rgba(0, 0, 0, 0.25);
    border-radius: get-vw(50px);
    text-align: center;

    top: get-vw(16px);
    font-size: get-vw(10px);
  }

  &-spinner {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20%;
    left: 0;
    right: 0;
  }

  &-bottomRow {
    position: absolute;
    bottom: get-vh(21px);
    text-align: center;
    color: white;

    &-report {
      font-size: get-vw(12px);
      margin-bottom: get-vh(4px);
      user-select: none;

      img {
        height: get-vw(20px);
        padding-bottom: get-vw(2px);
        margin-right: get-vw(4px);
      }
    }

    &-keepButton {
      @include resultPage-button {
        background-color: #f2f2f2;
        color: $primary-color;
      }
    }

    &-keepButton:lang(es),
    &-keepButton:lang(ru) {
      font-size: get-vw(12px);
    }

    &-keepButton:active {
      background-color: #b3b3b3;
    }

    &-keepButton:focus {
      outline: 0;
    }

    &-startButton {
      @include resultPage-button {
        background-color: #f2c94c;
      }
    }

    &-startButton:lang(es),
    &-startButton:lang(ru) {
      font-size: get-vw(12px);
    }

    &-startButton:active {
      background-color: #be920e;
    }

    &-startButton:focus {
      outline: 0;
    }

    &-startButton404 {
      @include resultPage-button {
        background-color: #f2c94c;
        margin-bottom: get-vh(40px);
      }
    }

    &-startButton404:lang(es),
    &-startButton404:lang(ru) {
      font-size: get-vw(12px);
    }

    &-startButton404:active {
      background-color: #be920e;
    }

    &-startButton404:focus {
      outline: 0;
    }
  }
}
