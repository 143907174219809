@import '../general.scss';
@import '../function.scss';

$editorHeader-action-height: get-vw(35px);

@mixin action {
  line-height: $editorHeader-action-height;
  @content;
}

.editorHeader {
  @include header {
    &-row {
      height: 100%;

      &-backAction {
        text-align: left;

        &-div {
          @include action {
            color: $secondary-color;

            img {
              height: get-vw(30px);
              margin-left: get-vw(-4px);
              margin-top: get-vw(-3px);
            }
          }
        }
      }

      &-outputAction {
        text-align: center;

        &-div {
          @include action {
            color: #ffffff;
            background-color: $primary-color;
            margin-left: get-vw(13px);

            border-radius: get-vw(5px);
            width: get-vw(85px);
            height: $editorHeader-action-height;
          }
        }

        &-div:lang(de) {
          font-size: get-vw(14px);
        }

        &-div:lang(ru) {
          font-size: get-vw(14px);
        }

        &-div:active {
          background-color: $primary-color-dark;
        }
      }

      &-cancelAction {
        text-align: left;

        &-text {
          @include action {
            color: $secondary-color;
            margin-left: get-vw(4px);
          }
        }

        &-text:lang(es) {
          font-size: get-vw(18px);
          margin-left: get-vw(6px);
        }

        &-text:lang(fr) {
          font-size: get-vw(18px);
        }

        &-text:lang(ru) {
          margin-left: get-vw(-4px);
          font-size: get-vw(18px);
        }
      }

      &-finishAction {
        text-align: right;

        &-text {
          @include action {
            color: $primary-color;
            margin-right: get-vw(2px);
          }
        }

        &-text:lang(es) {
          font-size: get-vw(18px);
          margin-left: get-vw(-18px);
        }

        &-text:lang(fr) {
          font-size: get-vw(18px);
          margin-left: get-vw(-16px);
        }

        &-text:lang(ru) {
          font-size: get-vw(18px);
          margin-right: get-vw(18px);
        }
      }

      &-editTools {
        display: flex;
        justify-content: space-around;
        align-items: center;

        img {
          width: get-vw(35px);
        }

        img:active {
          filter: brightness(50%);
        }
      }
    }
  }
}
