@import '../general.scss';
@import '../function.scss';

$category-margin: get-vw(8px);

@mixin category {
  width: 95%;
  height: get-vh(30px);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: get-vw(1px) solid;
  border-radius: get-vw(20px);
  padding-top: get-vw(2px);
  padding-left: get-vw(6px);
  padding-right: get-vw(6px);
  margin-left: auto;
  margin-right: auto;
  @content;

  img {
    @include category-img;
  }
}

@mixin category-img {
  width: get-vw(18px);
  padding-bottom: get-vw(4px);
  @content;
}

@mixin origin-color {
  border-color: #888888;
  background-color: transparent;
  color: #888888;
}

@mixin active-color {
  border-color: #308064;
  background-color: #308064;
  color: white;
}

.moreStickersModal {
  &-content {
    border: none;
  }

  &-body {
    height: get-vh(630px);
    font-size: get-vw(18px);
    text-align: center;
    padding: 0;
    color: white;

    &-closeAction {
      position: absolute;
      height: get-vw(30px);
      top: get-vh(10px);
      left: get-vh(15px);
      img {
        height: 100%;
      }
    }

    &-header {
      height: get-vh(60px);
      background-color: $primary-color;
    }

    &-categories {
      margin-top: get-vh(22px);
      margin-bottom: get-vh(15px);
      font-size: get-vw(12px);

      display: grid;
      grid-template-columns: 33% 33% 33%;
      justify-content: space-between;
      align-content: center;
    }

    &-categories:lang(de),
    &-categories:lang(fr) {
      font-size: get-vw(10px);
    }

    &-stickers {
      padding-top: get-vw(3px);
      padding-left: get-vw(30px);
      padding-right: get-vw(30px);
      max-height: get-vh(500px);
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;

      &-each {
        height: get-vw(75px);
        padding: get-vw(5px);
      }
    }
  }
}

.category1 {
  @include category {
    @include origin-color;

    &.active {
      @include active-color;
    }
  }
}

.category2 {
  @include category {
    @include origin-color;

    &.active {
      @include active-color;
    }
  }
}

.category3 {
  @include category {
    @include origin-color;

    &.active {
      @include active-color;
    }
  }
}
