@import '../general.scss';
@import '../function.scss';

.selectHeader {
  @include header {
    &-row {
      height: 100%;

      &-closeAction {
        text-align: left;

        img {
          height: get-vw(25px);
          margin-left: get-vw(5px);
        }
      }

      &-nextAction {
        text-align: right;

        a {
          text-decoration: none;
        }

        span {
          color: #4f4f4f;
          font-size: get-vw(20px);
          margin-right: get-vw(5px);
        }
      }
    }
  }
}
