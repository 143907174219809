@import '../general.scss';

.saveHeader {
  @include header {
    &-row {
      height: 100%;
      text-align: center;
      user-select: none;
    }
  }
}
