@import '../general.scss';
@import '../function.scss';
@import './ToolBox.scss';

$workingArea-margin: calculated-bottom-margin(
  $header-height,
  var(--workingAreaHeight),
  var(--toolBoxHeight)
);

#workingAreaWrapper {
  display: flex;
  justify-content: center;
}

@mixin workingArea {
  position: relative;
  overflow: hidden;
  width: $workingArea-width-general;
  height: $workingArea-height-general;
  box-shadow: 0 get-vw(4px) get-vw(10px) rgba(0, 0, 0, 0.25);
  transform-origin: top center;
  transition: transform 0.5s;
  @content;
}

.workingArea-BACKGROUND {
  @include workingArea {
    -webkit-transform: scale($workingArea-scale-backgrounds);
    transform: scale($workingArea-scale-backgrounds);
  }
}

.workingArea-TEXT {
  @include workingArea {
    -webkit-transform: scale($workingArea-scale-stickers-text);
    transform: scale($workingArea-scale-stickers-text);
  }
}

.workingArea-STICKER {
  @include workingArea {
    -webkit-transform: scale($workingArea-scale-stickers-text);
    transform: scale($workingArea-scale-stickers-text);
  }
}

.workingArea-NONE {
  @include workingArea {
    -webkit-transform: scale($workingArea-scale-general);
    transform: scale($workingArea-scale-general);
  }
}

#wallpaperTempBackground {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#wallpaperTempBackgroundImage {
  width: 100%;
  object-fit: cover;
}
