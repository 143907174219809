@import '../general.scss';
@import '../function.scss';
@import '../DesktopPage/index.scss';
@import '../HomePage/index.scss';

$savePage-bottomRow-button-height: get-vh(40px);
$pattern-tree-mobile: url('../../assets/pattern/tree.svg');

@function getSmallOutputScaleReverse() {
  @return calc(var(--savePageScale) / var(--outputScale));
}

@function getOutputImageMargin() {
  @return calc(var(--outputImageMargin) * 1px);
}

.savePage {
  height: getInnerHeight();
  overflow: hidden;

  &-image {
    box-shadow: 0 get-vw(4px) get-vw(10px) rgba(0, 0, 0, 0.25);
    transform-origin: 0 0;
    transform: translatex(getOutputImageMargin())
      scale(getSmallOutputScaleReverse());
  }

  &-backAction {
    img {
      position: absolute;
      height: get-vw(30px);
      top: get-vh(20px);
      left: get-vh(30px);
    }
  }

  &-bottomRow {
    height: auto;
    position: absolute;
    bottom: get-vh(30px);
    text-align: center;

    &-text {
      color: white;
      font-size: get-vw(12px);
      margin-bottom: get-vh(20px);
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      user-select: none;
    }

    @include button {
      height: $savePage-bottomRow-button-height;
      line-height: $savePage-bottomRow-button-height;
      user-select: none;
    }
  }

  &-toolTip {
    margin-left: get-vw(4px);
  }

  &-toolTip:lang(ru) {
    margin-left: get-vw(2px);
    font-size: get-vw(12px);
  }
}

.savePage:after {
  @include pattern {
    background: $pattern-tree-mobile repeat;
    background-size: 103%;
  }
}
