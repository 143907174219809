@import '../general.scss';
@import '../function.scss';
@import './WorkingArea.scss';

#previewImageAreaID {
  position: absolute;
  width: 1200px;
  height: 630px;
  background-color: red;
  z-index: 10;

  // 讓它不在畫面出現，輸出 image 時再調回來
  left: -2000px;
}

#previewImageID {
  transform: scale(0.408);
  transform-origin: 0 0;
  margin-top: 120px;
  margin-left: 158px;
}
