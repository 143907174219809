@import '../general.scss';
@import '../function.scss';
@import './ClearContentAlert.scss';

$textBoxModal-action-height: get-vw(38px);
$textBoxModal-textarea-fontsize: get-vw(16px);

.textBoxModal {
  margin-top: get-vh(170px);

  &-body {
    text-align: center;

    &-content {
      width: get-vw(270px);
      height: auto;
      @include modal-content;

      &-title {
        padding: 0;
        font-size: get-vw(18px);
      }

      &-textarea {
        padding: 0;
        width: get-vw(238px);
        height: get-vw(100px);
        padding-top: get-vw(8px);
        font-size: $textBoxModal-textarea-fontsize;

        &-form {
          outline: none;
          width: 100%;
          height: 100%;
          resize: none;
          background: #ffffff;
          border: get-vw(2px) solid #828282;
          border-radius: get-vw(5px);
        }
      }

      &-message {
        color: #828282;
        padding: 0;
        font-size: get-vw(12px);
        padding-top: get-vw(8px);
      }

      &-action {
        div {
          margin: auto;
          color: #ffffff;
          font-size: get-vw(16px);
          width: get-vw(90px);
          height: $textBoxModal-action-height;
          background: $primary-color;
          border-radius: get-vw(5px);
          margin-top: get-vw(9px);
          line-height: $textBoxModal-action-height;
        }

        div:lang(ru){
          width: get-vw(120px);
        }

        div:active {
          background: $primary-color-dark;
        }
      }
    }
  }
}

.editable-textBox {
  font-size: $textBoxModal-textarea-fontsize;
  width: auto;
  height: auto;
  padding-left: get-vw(4px);
  padding-right: get-vw(4px);

  user-select: text;
  -moz-user-select: text;
  -webkit-user-select: text;
}
