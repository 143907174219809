@import "../general.scss";

$pattern-tree-desktop: url("../../assets/pattern/tree.svg");
$desktopPage-margin: 3%;
$phone-rotate: rotate(-10deg);

@mixin pattern {
    content: "";
    position: absolute;
    margin-top: 10px;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: -1;
    @content;
}

@mixin phoneRotate {
    transform: $phone-rotate translate3d(0, 0, 0);
    -webkit-transform: $phone-rotate translate3d(0, 0, 0);
}

.desktopPage {
    height: 100vh;
    color: white;
    background-color: $primary-color;
    position: relative;
    z-index: 3;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;

    &-companyLogo {
        width: 12vw;
        position: absolute;
        top: 1vw;
        left: 1vw;
    }

    &-companyLogo:hover {
        cursor: pointer;
    }

    &-phone {
        position: absolute;
        top: 18%;
        left: 16%;
        width: 18%;
        z-index: 5;
        @include phoneRotate;
    }

    &-carousel {
        position: absolute;
        top: 24%;
        left: 16.5%;
        width: 17%;
        @include phoneRotate;

        &-item {
            width: 100%;

            img {
                width: 100%;
            }
        }
    }

    &-row {
        position: absolute;
        top: 10%;
        right: 1%;
        width: 65%;
        height: 80%;

        &-logoAndForest {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 2vw;
            height: 10%;

            img {
                height: 100%;
                margin-right: 1vw;
            }
        }

        &-logoAndForest:hover {
            cursor: pointer;
        }

        &-yellowMainName {
            width: 100%;
            height: 29%;
            margin-top: $desktopPage-margin;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                height: auto;
                max-width: 100%;
                max-height: 100%;
            }

            img:lang(ko),
            img:lang(zh-CN),
            img:lang(zh-TW) {
                max-width: 80%;
            }
        }

        &-yellowMainName:lang(de),
        &-yellowMainName:lang(es),
        &-yellowMainName:lang(fr),
        &-yellowMainName:lang(ru) {
            height: 26%;
        }

        &-yellowMainName:lang(ko),
        &-yellowMainName:lang(zh-CN),
        &-yellowMainName:lang(zh-TW) {
            height: 16%;
        }

        &-text {
            text-align: center;
            color: rgba(0, 0, 0, 0.7);
            margin-top: $desktopPage-margin;
            margin-left: auto;
            margin-right: auto;
            font-size: 1.5vw;
            line-height: 2.5vw;
        }

        &-text:lang(ru) {
            width: 80%;
            font-size: 1.2vw;
        }

        &-qrcode {
            margin-left: auto;
            margin-right: auto;
            width: 16vw;

            img {
                width: 100%;
            }
        }

        &-qrcode:lang(ko),
        &-qrcode:lang(zh-CN),
        &-qrcode:lang(zh-TW) {
            width: 20vw;
        }
    }
}

.desktopPage:after {
    @include pattern {
        background: $pattern-tree-desktop repeat;
        background-size: auto;
    }
}

.icp {
    position: absolute;
    bottom: 4px;
    right: 6px;
}

.icp > a {
    font-size: 13px;
    opacity: 0.6;
    color: #000;
}
