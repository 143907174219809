$design-width: 375;
$design-height: 667;

@function calculated-vh($height) {
  @return calc(var(--vh) * #{$height});
}

@function calculated-bottom-margin($top, $middle, $bottom) {
  @return calc((var(--vh) * 100 - #{$top} - #{$middle} - #{$bottom}));
}

@function getInnerHeight() {
  @return calc((var(--vh) * 100));
}

@function get-vw($target) {
  $vw-context: ($design-width * 0.01) * 1px;
  @return ($target / $vw-context) * 1vw;
}

@function get-vh($target) {
  $vh-context: ($design-height * 0.01) * 1px;
  @return calculated-vh($target / $vh-context);
}
