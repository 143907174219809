@import '../general.scss';
@import '../function.scss';

$selectWallpaper-ratio: 0.8;
$selectWallpaper-height: get-vh(130px);
$selectWallpaper-preview-height: get-vw(99px * $selectWallpaper-ratio);

@function calculated-preview-top-bottom-margin() {
  @return calc(
    (#{$selectWallpaper-height} - #{$selectWallpaper-preview-height}) / 2
  );
}

@mixin wallpaperPreview {
  width: get-vw(68px * $selectWallpaper-ratio);
  height: $selectWallpaper-preview-height;
  border-radius: get-vw(8px);

  margin-top: calculated-preview-top-bottom-margin();
  margin-bottom: calculated-preview-top-bottom-margin();
  margin-left: get-vw(15px);
  &:last-of-type {
    margin-right: get-vw(15px);
  }
}

.selectWallpaper {
  width: 100%;
  position: absolute;
  bottom: 0;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  height: $selectWallpaper-height;
  background-color: #f4f4f4;
  box-shadow: 0 get-vw(-4px) get-vw(5px) rgba(0, 0, 0, 0.1);

  &-gallery {
    @include scrollFlexbox {
      @include wallpaperPreview;
    }
  }
}
