@import '../general.scss';
@import '../function.scss';
@import '../SelectPage/SelectWallpaper.scss';

$toolbox-height-general: get-vh(55px);
$toolbox-height-stickers: get-vh(80px);
$toolbox-height-text: get-vh(80px);
$toolbox-height-backgrounds: get-vh(130px);

$colorCircle-height: get-vw(30px);

$background-tool-color: #5188a3;
$text-tool-color: #9dcd82;
$sticker-tool-color: #f2997a;

@function calculated-colorCircle-top-bottom-margin() {
  @return calc((#{$toolbox-height-text} - #{$colorCircle-height}) / 2);
}

@function calculated-stickers-top-bottom-margin() {
  @return calc((#{$toolbox-height-stickers} - #{$origin-sticker-height}) / 2);
}

@mixin icon {
  img {
    height: get-vw(35px);
  }

  img:lang(de),
  img:lang(en),
  div:lang(fr),
  div:lang(ja) {
    height: get-vw(22px);
  }
  @content;
}

.toolbox {
  position: absolute;
  bottom: 0;

  &-col {
    height: $toolbox-height-general;
    padding: 0;

    div {
      height: 100%;
      width: get-vw(123px);
      background-color: #ffffff;
      font-size: get-vw(20px);
      padding-right: get-vw(8px);

      display: flex;
      justify-content: center;
      align-items: center;
    }

    div:lang(de),
    div:lang(en),
    div:lang(fr),
    div:lang(ja) {
      font-size: get-vw(16px);
    }

    div:active {
      background-color: rgba(0, 0, 0, 0.1);
    }

    &-background {
      color: $background-tool-color;
      @include icon;
    }

    &-text {
      color: $text-tool-color;
      @include icon;
    }

    &-sticker {
      color: $sticker-tool-color;
      @include icon;
    }
  }

  &-editCol {
    padding: 0;

    &-editingSticker {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      height: $toolbox-height-stickers;
      background-color: #f4f4f4;
      box-shadow: 0 get-vw(-4px) get-vw(5px) rgba(0, 0, 0, 0.1);

      &-flex {
        @include scrollFlexbox;

        &-block {
          display: flex;
          flex-direction: column;
          font-size: get-vw(12px);
          text-align: center;

          img {
            width: get-vw(40px);
            height: get-vw(40px);
            margin-left: get-vw(15px);
            margin-right: get-vw(15px);
          }
        }
      }
    }

    &-editingText {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      height: $toolbox-height-text;
      background-color: #f4f4f4;
      box-shadow: 0 get-vw(-4px) get-vw(5px) rgba(0, 0, 0, 0.1);

      &-flex {
        @include scrollFlexbox;

        &-colorCircle {
          display: flex;
          width: get-vw(30px);
          height: get-vw(30px);
          border-radius: 50%;
          border: get-vw(2px) solid #ffffff;

          margin-top: calculated-colorCircle-top-bottom-margin();
          margin-bottom: calculated-colorCircle-top-bottom-margin();

          margin-left: get-vw(19px);
          &:last-of-type {
            margin-right: get-vw(19px);
          }
        }
      }
    }

    &-editingBackgrounds {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      height: $toolbox-height-backgrounds;
      background-color: #f4f4f4;
      box-shadow: 0 get-vw(-4px) get-vw(5px) rgba(0, 0, 0, 0.1);

      &-flex {
        @include scrollFlexbox {
          @include wallpaperPreview;
        }
      }
    }
  }
}

.origin-sticker {
  width: $origin-sticker-width;
  height: $origin-sticker-height;
  margin-top: calculated-stickers-top-bottom-margin();
  margin-bottom: calculated-stickers-top-bottom-margin();
  margin-right: get-vw(15px);
}

.resize-corner {
  width: get-vw(30px);
  height: get-vw(30px);
  background-image: url('../../assets/icon/scale.png');
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: -14px;
  right: -14px;
}

.resize-drag {
  display: flex;
  width: auto;
  height: auto;
  position: absolute;
  transform-origin: center center;
  touch-action: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;

  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  image-rendering: optimizeSpeed;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  -ms-interpolation-mode: nearest-neighbor;
}
