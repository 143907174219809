@import '../general.scss';
@import '../function.scss';

$clearAllObjectsButton-height: get-vh(31px);

#clearAllObjectsButton {
  width: get-vw(83px);
  height: auto;
  padding: get-vw(1px);
  position: absolute;
  z-index: 1;
  top: 16%;

  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;

  color: white;
  background: rgba(0, 0, 0, 0.5);
  border-radius: get-vw(50px);
  font-size: get-vw(14px);
  text-align: center;
  line-height: $clearAllObjectsButton-height;
}

#clearAllObjectsButton:lang(de),
#clearAllObjectsButton:lang(ru){
  width: get-vw(110px);
}

#clearAllObjectsButton:active {
  background: rgba(0, 0, 0, 0.8);
}

.editorPage {
  &-spinner {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    margin-top: 35%;
    left: 0;
    right: 0;
    z-index: 10;
  }
}
