@import "../general.scss";
@import "../function.scss";
@import "../DesktopPage/index.scss";

$homePage-title-height: get-vh(90px);
$homePage-button-height: get-vh(56px);
$homePage-img-top: 30%;
$pattern-tree-mobile: url("../../assets/pattern/tree_mobile.svg");

@mixin wallpaper {
    width: get-vh(187px);
    height: get-vh(333px);
    background-color: white;
    border: get-vw(6px) solid #ffffff;
    box-shadow: 0 get-vw(4px) get-vw(10px) rgba(0, 0, 0, 0.25);
    border-radius: get-vw(4px);
    position: absolute;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    @content;
}

@mixin button {
    &-button {
        border: 0;
        padding: 0;

        background-color: #f2c94c;
        width: 100%;
        border-radius: get-vw(50px);
        @content;
    }

    &-button:active {
        background-color: #be920e;
    }

    &-button:focus {
        outline: 0;
    }
}

@mixin logoAndForest {
    display: flex;
    justify-content: center;
    padding-top: get-vh(18px);
    margin-bottom: get-vh(15px);
    img {
        height: get-vw(25px);
        margin-right: get-vw(8px);
    }
}

@mixin title {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #265441;
    height: get-vh(90px);
    img {
        width: 90%;
    }

    img:lang(de) {
        width: 80%;
    }

    img:lang(fr),
    img:lang(ru) {
        width: 70%;
    }

    img:lang(es) {
        width: 90%;
    }

    img:lang(en),
    img:lang(ja) {
        width: 60%;
    }
}

.homePage {
    text-align: center;
    height: getInnerHeight();
    position: relative;
    z-index: 3;
    color: white;
    overflow: hidden;

    &-logoAndForest {
        @include logoAndForest;
    }

    &-logoAndForest:lang(de),
    &-logoAndForest:lang(en),
    &-logoAndForest:lang(es),
    &-logoAndForest:lang(fr),
    &-logoAndForest:lang(ja),
    &-logoAndForest:lang(ru) {
        padding-top: get-vh(10px);
        margin-bottom: get-vh(10px);
    }

    &-title {
        @include title;
    }

    &-title:lang(de),
    &-title:lang(en),
    &-title:lang(es),
    &-title:lang(fr),
    &-title:lang(ja),
    &-title:lang(ru) {
        height: get-vh(120px);
    }

    &-bottomRow {
        position: absolute;
        bottom: get-vh(36px);

        @include button {
            color: #5e3614;
            height: $homePage-button-height;
            line-height: $homePage-button-height;
            font-size: get-vw(26px);
            box-shadow: 0 get-vw(4px) get-vw(20px) rgba(0, 0, 0, 0.25);
        }
    }

    &-wallpaper1 {
        @include wallpaper {
            transform: rotate(6.55deg) translate3d(0, 0, 0);
            -webkit-transform: rotate(6.55deg) translate3d(0, 0, 0);
            top: $homePage-img-top;
            left: 0;
            right: -2%;
            margin-left: auto;
            margin-right: auto;
            z-index: 5;
        }
    }

    &-wallpaper2 {
        @include wallpaper {
            transform: rotate(-11.89deg) translate3d(0, 0, 0);
            -webkit-transform: rotate(-11.89deg) translate3d(0, 0, 0);
            top: $homePage-img-top + 1%;
            left: -5%;
            margin-right: auto;
        }
    }

    &-wallpaper3 {
        @include wallpaper {
            transform: rotate(-3.95deg) translate3d(0, 0, 0);
            -webkit-transform: rotate(-3.95deg) translate3d(0, 0, 0);
            top: $homePage-img-top;
            right: -10%;
            margin-right: auto;
        }
    }
}

.homePage:after {
    @include pattern {
        background: $pattern-tree-mobile repeat;
        background-size: 86%;
    }
}

.icp--in-app {
    position: absolute;
    bottom: 0;
    right: 2px;
}

.icp--in-app > a {
    font-size: 12px;
    color: #000;
    opacity: 0.6;
}
