@import '../function.scss';

@function multiplyWithMobileScale($targetScale) {
  @return calc(var(--mobileScale) * #{$targetScale});
}

$primary-color: #51a387;
$primary-color-dark: #2a5547;
$secondary-color: #828282;
$header-height: get-vh(75px);

$origin-sticker-width: get-vh(60px);
$origin-sticker-height: get-vh(60px);

$workingArea-width-general: 241px;
$workingArea-width-general-no-pixel: 241;
$workingArea-height-general: 429px;

$workingArea-scale-general: multiplyWithMobileScale(1);
$workingArea-scale-backgrounds: multiplyWithMobileScale(0.85);
$workingArea-scale-stickers-text: multiplyWithMobileScale(0.95);

@mixin header {
  height: $header-height;
  font-size: get-vw(20px);
  @content;
}

@mixin scrollFlexbox {
  display: inline-flex;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    @content;
  }
}
