@import '../general.scss';
@import '../function.scss';

.displayWallpaper {
  width: $workingArea-width-general;
  height: $workingArea-height-general;
  box-shadow: 0 get-vw(4px) get-vw(10px) rgba(0, 0, 0, 0.25);

  margin-left: auto;
  margin-right: auto;

  transform-origin: top center;
  -webkit-transform: scale($workingArea-scale-backgrounds);
  transform: scale($workingArea-scale-backgrounds);
}
