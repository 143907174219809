@import '../general.scss';
@import '../function.scss';

$clearContentAlert-action-height: get-vw(40px);

@mixin button {
  width: get-vw(110px);
  height: $clearContentAlert-action-height;
  border-radius: get-vw(5px);
}

@mixin message {
  padding: 0;
  height: get-vw(50px);
  font-size: get-vw(12px);
  @content;
}

@mixin modal-content {
  border-radius: get-vw(10px);
  background: #f4f4f4;
  margin-left: auto;
  margin-right: auto;
}

.clearContentAlert {
  margin-top: get-vh(210px);

  &-body {
    text-align: center;

    &-content {
      width: get-vw(270px);
      height: auto;
      @include modal-content;

      &-title {
        padding: 0;
        font-size: get-vw(15px);
        margin-top: get-vw(8px);
      }

      &-message1 {
        @include message {
          padding-top: get-vw(10px);
        }
      }

      &-message2 {
        @include message {
          padding-top: get-vw(16px);
        }
      }

      &-message3 {
        @include message {
          padding-top: get-vw(16px);
        }
      }

      &-button {
        color: white;
        padding: 0;
        margin-top: get-vw(15px);
        padding-right: get-vw(12px);
        padding-left: get-vw(12px);

        &-action1 {
          @include button;
          background-color: #bdbdbd;
          margin-right: get-vw(10px);
          line-height: $clearContentAlert-action-height;
        }

        &-action1:lang(ru) {
          font-size: get-vw(12px);
        }

        &-action1:active {
          background-color: #808080;
        }

        &-action2 {
          @include button;
          background-color: #dd7878;
          margin-left: get-vw(10px);
          line-height: $clearContentAlert-action-height;
          a {
            color: white;
          }
        }

        &-action2:active {
          background-color: #b82e2e;
        }

        &-action2:lang(ru) {
          font-size: get-vw(12px);
        }
      }
    }
  }
}
